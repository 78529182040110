import { AppLayout } from 'portal-commons';
import { ComponentType } from 'react';

import { useAuthContext } from '../contexts';
import { Header, NoAccess, PrimarySidebar } from '../shared_elements';
import { APP_ROLES } from '../constants';

const Footer = () => {
  return (
    <div className="footer-hoc-block">
      <div className="text-center">
        <p>
          Confidentiality Notice: The information accessed through The Campaign
          Registry DCA Portal is intended for use by authorized individuals
          only. Any unauthorized access or use of this information is
          prohibited. By using The Campaign Registry DCA Portal, you agree to
          keep all accessed information confidential in accordance with the{' '}
          <a
            href="https://www.campaignregistry.com/TCR-T&Cs.pdf"
            rel="noopener noreferrer"
            target="_blank"
          >
            Terms of Use
          </a>
          .
        </p>
      </div>
    </div>
  );
};

const withAppLayout =
  <P extends Record<string, never>>(
    WrappedComponent: ComponentType<P>,
    options: Record<string, any> = {}
  ) =>
  (props: P) => {
    const { role } = useAuthContext();

    const renderMain = () => (
      <main className="flex-1">
        <div className={options.authorized ? 'page-layout' : ''}>
          {options.authorized ? (
            <WrappedComponent
              {...props}
              access={role ? APP_ROLES[role] : []}
              role={role}
            />
          ) : (
            <NoAccess />
          )}
        </div>
      </main>
    );

    return (
      <AppLayout
        authenticated
        sideNavigation={
          <aside style={{ height: '100%' }}>
            <PrimarySidebar authenticated />
          </aside>
        }
        topBar={
          <Header authenticated title={options.title} note={options.note} />
        }
        footer={options.authorized && <Footer />}
      >
        <div className="full-width">{renderMain()}</div>
      </AppLayout>
    );
  };

export default withAppLayout;
